.AttachmentContainer
    display: grid
    .DetailHeader
        background: $Peach
        font-size: 20px
        min-height: 70px
        height: max-content
        display: flex
        align-items: center
        span
            width: 100%
            text-align: center
            display: block
        .DetailButtons
            width: 50px
            height: 70px
            display: flex
            align-items: center
            justify-content: center
            border-right: 1px solid black
            a
                display: flex
                color: black
                svg
                    cursor: pointer
                    padding: 10px
                    transition-duration: .2s
                    &:hover
                        background: grey
    .fileContent
        display: flex
        color: black
        background: white
        font-size: 9pt
        align-items: center
        padding: 10px
        text-decoration: none
        &:hover
            text-decoration: underline
        &:nth-child(odd)
            background: $Alabaster
.Modalattachment
    display: block
    .ModalAttSubtitle
        display: block
        opacity: .8
        font-family: "Roboto","Helvetica","Arial",sans-serif
    button
        display: flex
        justify-content: center
        margin: auto
        background: $Rio_grande