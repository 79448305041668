* {
  padding: 0;
  margin: 0;
}

#mainContainer {
  height: 100vh;
}

.mainContainer {
  width: 100%;
  overflow-x: hidden;
  font-family: Verdana, Tahoma, Arial, sans-serif;
}
.mainContainer .MainStyled {
  margin-top: 100px;
}

.AppBarSearch {
  background-color: #B9D301 !important;
  color: #666666 !important;
}

.ModalLoading {
  width: 300px;
  display: grid;
  justify-items: center;
  padding: 20px;
}
.ModalLoading img {
  width: 200px;
}

#MainHeader {
  width: 100%;
  height: 100px;
  background: white;
  display: grid;
  justify-items: flex-start;
  font-family: Verdana, Tahoma, Arial, sans-serif;
  position: fixed;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
#MainHeader #disclaimer {
  height: 20px;
  width: 100%;
  padding: 10px;
  background: #B9D301;
}
#MainHeader #disclaimer span {
  color: #666666;
  font-size: 8pt;
}
#MainHeader #logo {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
}
#MainHeader #logo img {
  height: 50px;
}
#MainHeader #logo span {
  margin-left: 30px;
  font-size: 1.2em;
  color: #004236;
}

.searchComponent {
  padding: 20px 0;
}

.shareTable .ContainerTable {
  padding: 20px;
}
.shareTable table tbody tr {
  cursor: pointer;
}
.shareTable table tr th {
  background: #00695f !important;
  color: white;
}
.shareTable .titleHeadersTable {
  text-align: center !important;
  padding: 5px;
  font-size: 11pt;
  border: 1px solid white;
}
.shareTable .titleHeadersTable span {
  justify-content: center;
}
.shareTable .expandColumn {
  width: 250px !important;
}

.DetailContainer {
  border: 1px solid black;
  margin: 20px;
}
.DetailContainer .DetailHeader {
  background: #ffeaad;
  font-size: 20px;
  height: max-content;
  display: flex;
  align-items: center;
}
.DetailContainer .DetailHeader span {
  width: 100%;
  text-align: center;
  display: block;
}
.DetailContainer .DetailHeader .DetailButtons {
  width: 50px;
  height: 100%;
  display: flex;
  border-right: 1px solid black;
}
.DetailContainer .DetailHeader .DetailButtons a {
  display: flex;
  color: black;
}
.DetailContainer .DetailHeader .DetailButtons a svg {
  cursor: pointer;
  padding: 10px;
  transition-duration: 0.2s;
}
.DetailContainer .DetailHeader .DetailButtons a svg:hover {
  background: grey;
}
.DetailContainer .DetailBody {
  display: grid;
  border-top: 1px solid black;
  height: 500px;
  overflow-y: scroll;
}
.DetailContainer .DetailBody .DetailBodyContent {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
}
.DetailContainer .DetailBody .DetailBodyContent .DetailHeader {
  background: white;
  width: 50%;
  border-right: 1px solid black;
  min-height: 90px;
}
.DetailContainer .DetailBody .DetailBodyContent .DetailDesc {
  color: black;
  width: 50%;
  overflow: scroll;
  padding: 10px;
  height: 70px;
  display: flex;
  align-items: center;
}
.DetailContainer .DetailBody .DetailBodyContent .DetailDesc div {
  height: 70px;
}

.AttachmentContainer {
  display: grid;
}
.AttachmentContainer .DetailHeader {
  background: #ffeaad;
  font-size: 20px;
  min-height: 70px;
  height: max-content;
  display: flex;
  align-items: center;
}
.AttachmentContainer .DetailHeader span {
  width: 100%;
  text-align: center;
  display: block;
}
.AttachmentContainer .DetailHeader .DetailButtons {
  width: 50px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid black;
}
.AttachmentContainer .DetailHeader .DetailButtons a {
  display: flex;
  color: black;
}
.AttachmentContainer .DetailHeader .DetailButtons a svg {
  cursor: pointer;
  padding: 10px;
  transition-duration: 0.2s;
}
.AttachmentContainer .DetailHeader .DetailButtons a svg:hover {
  background: grey;
}
.AttachmentContainer .fileContent {
  display: flex;
  color: black;
  background: white;
  font-size: 9pt;
  align-items: center;
  padding: 10px;
  text-decoration: none;
}
.AttachmentContainer .fileContent:hover {
  text-decoration: underline;
}
.AttachmentContainer .fileContent:nth-child(odd) {
  background: #f9f9f9;
}

.Modalattachment {
  display: block;
}
.Modalattachment .ModalAttSubtitle {
  display: block;
  opacity: 0.8;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.Modalattachment button {
  display: flex;
  justify-content: center;
  margin: auto;
  background: #B9D301;
}

