#MainHeader
    width: 100%
    height: 100px
    background: white
    display: grid
    justify-items: flex-start
    font-family: Verdana, Tahoma, Arial, sans-serif
    position: fixed
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
    #disclaimer
        height: 20px
        width: 100%
        padding: 10px
        background: $Rio_grande
        span
            color: $Dove_gray
            font-size: 8pt
    #logo
        width: 100%
        height: 50px
        display: flex
        align-items: flex-end
        img
            height: 50px
        span
            margin-left: 30px
            font-size: 1.2em
            color: #004236