.searchComponent
    padding: 20px 0
.shareTable
    .ContainerTable
        padding: 20px
    table
        tbody tr
            cursor: pointer
        tr th
            background: $BlueStone !important
            color: white
    .titleHeadersTable
        text-align: center !important
        padding: 5px
        font-size: 11pt
        border: 1px solid white
        span
            justify-content: center
    .expandColumn 
        width: 250px !important