.DetailContainer
    border: 1px solid black
    margin: 20px
    .DetailHeader
        background: $Peach
        font-size: 20px
        height: max-content
        display: flex
        align-items: center
        span
            width: 100%
            text-align: center
            display: block
        .DetailButtons
            width: 50px
            height: 100%
            display: flex
            border-right: 1px solid black
            a
                display: flex
                color: black
                svg
                    cursor: pointer
                    padding: 10px
                    transition-duration: .2s
                    &:hover
                        background: grey
    .DetailBody
        display: grid
        border-top: 1px solid black
        height: 500px
        overflow-y: scroll
        .DetailBodyContent
            display: flex
            align-items: center
            border-bottom: 1px solid black
            .DetailHeader
                background: white
                width: 50%
                border-right: 1px solid black
                min-height: 90px
            .DetailDesc
                color: black
                width: 50%
                overflow: scroll
                padding: 10px
                height: 70px
                display: flex
                align-items: center
                div
                    height: 70px