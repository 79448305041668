@import Colors

*
    padding: 0
    margin: 0
#mainContainer
    height: 100vh
.mainContainer
    width: 100%
    overflow-x: hidden
    font-family: Verdana, Tahoma, Arial, sans-serif
    .MainStyled
        margin-top: 100px
.AppBarSearch
    background-color: $Rio_grande !important
    color: $Dove_gray !important

.ModalLoading
    width: 300px
    display: grid
    justify-items: center
    padding: 20px
    img
        width: 200px
@import components/_Header
@import components/Home/_Home
@import components/Detail/Detail
@import components/Attachment/Attachment